<template>
  <div class="pacientesAgregados-container">
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="false"
      :preview-modal="true"
      paginate-elements-by-height="1100"
      filename="HISTORIAL OFTALMOLOGÍA"
      :pdf-quality="2"
      :manual-pagination="true"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="800px"
      ref="html2Pdf"
    >
      <section slot="pdf-content">
        <h1 style="color: rgba(111, 33, 199, 0.739);">Pacientes agregados</h1>
        <el-row :gutter="20">
          <el-col :span="6" v-if="projectInformation">
            <div>
              <el-statistic
                group-separator=","
                :value="projectInformation.length"
                :title="title"
              />
            </div>
          </el-col>
          <el-col :span="6">
            <div>
              <el-statistic  title="Distribución de sexo H:M">
                <template slot="formatter">
                    <label>{{ hombre }}</label>/<label>{{ mujer }}</label>
                </template>
              </el-statistic>
            </div>
          </el-col>
        </el-row>
        <el-table
          style="margin-top: 20px;"
          :data="projectInformation"
          lazy
        >
          <el-table-column property="patientId" label="ID EXPEDIENTE" />
          <el-table-column property="firstName" label="NOMBRE" />
          <el-table-column property="lastName" label="APELLIDO" />
          <el-table-column property="edad" label="EDAD" />
          <el-table-column property="sex" label="SEXO" />
        </el-table>
      </section>
    </vue-html2pdf>
    <h1>Pacientes agregados</h1>
    <el-row :gutter="20">
      <el-col :span="6" v-if="projectInformation">
        <div>
          <el-statistic
            group-separator=","
            :value="totalRegistrados"
            :title="title"
          />
        </div>
      </el-col>
      <el-col :span="6">
        <div>
          <el-statistic  title="Distribución de sexo H:M">
            <template slot="formatter">
                <label>{{ hombre }}</label>/<label>{{ mujer }}</label>
            </template>
          </el-statistic>
        </div>
      </el-col>
    </el-row>
    <el-button id="btn-fundacion" @click="generateReport()">Imprimir</el-button>
    <div class="table-proyecto-info">
      <el-table
        :data="projectInformation"
        @row-click="clickCell"
        stripe
      >
        <el-table-column property="patientId" label="ID EXPEDIENTE" />
        <el-table-column property="firstName" label="NOMBRE" />
        <el-table-column property="lastName" label="APELLIDO" />
        <el-table-column property="edad" label="EDAD" />
        <el-table-column property="sex" label="SEXO" />
      </el-table>
    </div>
    <el-dialog
      title="Información del paciente"
      :visible.sync="dialogPatient"
      width="30%">
      <!-- {{ patientSelected }} -->
        <span>
          {{ patientSelected.firstName }}
          {{ patientSelected.lastName }}
          ({{ patientSelected.edad }} Años)
        </span><br>
        <span slot="footer" class="dialog-footer">
        <el-button @click="dialogPatient = false">Cerrar</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf';
import ProyectoAPI from '../../API/comunidad/proyectos';
import PatientAPI from '../../API/comunidad/patient';

export default {
  data() {
    return {
      like: true,
      totalRegistrados: 0,
      value1: null,
      value2: null,
      title: 'Pacientes agregados',
      input: 'Hello Element UI!',
      projectInformation: [],
      proyectoId: '',
      hombre: 0,
      mujer: 0,
      patientSelected: {},
      dialogPatient: false,
    };
  },
  components: {
    VueHtml2pdf,
  },
  methods: {
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },
    async getProjectInformation() {
      try {
        this.$loading();
        const request = await PatientAPI.getPatients(this.proyectoId);
        if (request) {
          await request.forEach((element) => {
            // eslint-disable-next-line max-len
            const edad = new Date().getFullYear() - new Date(element.patient.info[0].birthdate).getFullYear();
            const newObject = { edad, ...element.patient.info[0] };
            this.projectInformation.push(newObject);
          });
          await this.gernderDsitribution();
          this.changeGenderBySex();
          this.totalRegistrados = request.length;
          setTimeout(() => {
            this.$loading().close();
          }, 1000);
        }
      } catch (error) {
        this.$loading().close();
      }
    },
    // FUNCION PARA CONTAR LA CANTIDAD DE PACIENTES POR SEXO DEL PROYECTO SELECCIONADO
    async gernderDsitribution() {
      this.projectInformation.forEach((patient) => {
        if (patient.sex === 'Masculino') {
          this.hombre += 1;
        } else if (patient.sex === 'Femenino') {
          this.mujer += 1;
        }
      });
    },
    changeGenderBySex() {
      // cambiar masculino por hombre y femenino por mujer
      this.projectInformation.forEach((patient, idx) => {
        if (patient.sex === 'Femenino') {
          this.projectInformation[idx].sex = 'Mujer';
        } else {
          this.projectInformation[idx].sex = 'Hombre';
        }
      });
    },
    // abrir modal con la informacion del paciente
    clickCell(row) {
      this.dialogPatient = true;
      this.patientSelected = row;
    },
  },
  async created() {
    this.proyectoId = this.$route.params.proyectoId;
    await this.getProjectInformation();
  },
};
</script>

<style lang="scss">
.pacientesAgregados-container {
  padding: 15px;
  h1 {
    color: rgba(111, 33, 199, 0.739);
  }
}
.like {
  cursor: pointer;
  font-size: 25px;
  display: inline-block;
}
.table-proyecto-info {
  padding: 20px;
}
#btn-fundacion {
  float: right;
  background-color: rgba(111, 33, 199, 0.739);
  color: #fff;
  font-weight: bold;
  border-radius: 8px;
  border: none;
}
span {
  font-weight: bold;
  font-size: 16px;
}
</style>
