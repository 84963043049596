import http from '../index';

const BASEURI = 'patient/comunidad';

const comunidadProyectoApi = {
  // eslint-disable-next-line consistent-return
  getcomunidadProyectos: async () => {
    try {
      const request = await http.get(BASEURI);
      const { data } = request;
      if (data) {
        return data;
      }
    } catch (error) {
      this.$errorNotify(error);
    }
  },
  // eslint-disable-next-line consistent-return
  getcomunidadProyecto: async (name) => {
    try {
      const request = await http.get(`${BASEURI}/${name}`);
      const { data } = request;
      if (data) return data;
    } catch (error) {
      this.$errorNotify(error);
    }
  },
  // eslint-disable-next-line consistent-return
  postcomunidadProyecto: async (data) => {
    try {
      const request = await http.post(BASEURI, data);
      if (request) {
        return request;
      }
    } catch (error) {
      this.$errorNotify(error);
    }
  },
  // comprobar si exite un proyecto en status activo
  // eslint-disable-next-line consistent-return
  getcomunidadProyectoActivo: async (name) => {
    try {
      const request = await http.get(`${BASEURI}/active/${name}`);
      const { data } = request;
      if (data) return data;
    } catch (error) {
      this.$errorNotify(error);
    }
  },
  // eslint-disable-next-line consistent-return
  patchProyecto: async (data) => {
    try {
      const request = await http.patch(`${BASEURI}/${data.id}`, data);
      if (request) {
        return request;
      }
    } catch (error) {
      this.$errorNotify(error);
    }
  },
  // eslint-disable-next-line consistent-return
  deleteProyecto: async (id) => {
    try {
      const request = await http.delete(`${BASEURI}/${id}`);
      if (request) {
        return request;
      }
    } catch (error) {
      this.$errorNotify(error);
    }
  },
};

export default comunidadProyectoApi;
