import http from '../index';

const BASEURI = 'patient/patientComunidad';
const BASEURIPATIENTINFO = 'patient';
const BASE_URI_CITAS = 'patient/subsecuente';

const PatientsAPI = {
  // eslint-disable-next-line consistent-return
  getPatients: async (proyectoComunidadId) => {
    try {
      const request = await http.get(`${BASEURI}/${proyectoComunidadId}`);
      if (request) {
        return request.data;
      }
    } catch (error) {
      this.$errorNotify(error);
    }
  },
  // SE CREAN LOS DATOS DEL PACIENTE EN LA COMUNIDAD
  // eslint-disable-next-line consistent-return
  async postPatientComunidad(proyectoId, data) {
    try {
      const request = await http.post(`${BASEURI}/comunidad/${proyectoId}`, data);
      if (request) {
        return request.data;
      }
    } catch (error) {
      this.$errorNotify(error);
    }
  },
  // SE CREA LA INFORMACION DEL PACIENTE (patient+patientInfo)
  // eslint-disable-next-line consistent-return
  async postPatient(data) {
    try {
      const request = await http.post(BASEURI, data);
      if (request) {
        return request.data;
      }
    } catch (error) {
      this.$errorNotify(error);
    }
  },
  // SE CREA LA INFORMACION DEL PACIENTE (patientProfile)
  // eslint-disable-next-line consistent-return
  async postPatientProfile(patient, data) {
    try {
      const patientId = patient;
      const request = await http.post(`${BASEURIPATIENTINFO}/profile/${patientId}`, data);
      if (request) {
        return request.data;
      }
    } catch (error) {
      this.$errorNotify(error);
    }
  },
  // SE ACTUALIZA LA INFORMACION DEL PACIENTE (patientProfile)
  // eslint-disable-next-line consistent-return
  async putPatientProfile(patient, data) {
    try {
      const patientId = patient;
      const request = await http.put(`${BASEURIPATIENTINFO}/profile/${patientId}`, data);
      if (request) {
        return request.data;
      }
    } catch (error) {
      this.$errorNotify(error);
    }
  },
  // SE OBTIENEN TODAS LAS CITAS DE UN PACIENTE
  // eslint-disable-next-line consistent-return
  async getPatientCitas(patientId) {
    try {
      const request = await http.get(`${BASE_URI_CITAS}/multiple/${patientId}`);
      if (request) {
        return request.data;
      }
    } catch (error) {
      this.$errorNotify(error);
    }
  },
  // SE OBTIENE LA INFORMACION BASICA DE UN PACIENTE
  // eslint-disable-next-line consistent-return
  async getPatientInfo(patientId) {
    try {
      const request = await http.get(`${BASEURIPATIENTINFO}/info/${patientId}`);
      if (request) {
        return request.data;
      }
    } catch (error) {
      this.$errorNotify(error);
    }
  },
  // SE OBTIENE TODA LA INFORMACION DE UN PACIENTE
  // eslint-disable-next-line consistent-return
  async getPatientProfile(patientId) {
    try {
      const request = await http.get(`${BASEURIPATIENTINFO}/profile/${patientId}`);
      if (request) {
        return request.data;
      }
    } catch (error) {
      this.$errorNotify(error);
    }
  },
  // SE ELIMINA UN PACIENTE
  // eslint-disable-next-line consistent-return
  async deletePatient(patientId) {
    try {
      const request = await http.delete(`${BASEURI}/comunidad/${patientId}`);
      if (request) {
        return request;
      }
    } catch (error) {
      this.$errorNotify(error);
    }
  },
  // SE ACTUALIZA LA INFORMACION DEL PACIENTE (patient)
  // eslint-disable-next-line consistent-return
  async putPatient(proyectoId, data) {
    try {
      const { patientId } = data;
      const request = await http.patch(`${BASEURI}/${proyectoId}/${patientId}`, data);
      if (request) {
        return request;
      }
    } catch (error) {
      this.$errorNotify(error);
    }
  },
  // SE OBTIENE LA INFORMACION DE UN PACIENTE EN ESPECIFICO DE LA COMUNIDAD
  // eslint-disable-next-line consistent-return
  async getPatientComunidad(proyectoId, patientId) {
    try {
      const request = await http.get(`${BASEURI}/comunidad/${proyectoId}/${patientId}`);
      if (request) {
        return request.data;
      }
    } catch (error) {
      this.$errorNotify(error);
    }
  },

  // eslint-disable-next-line consistent-return
  async patchComentarioComunidad(proyectoId, patientId, data) {
    try {
      const request = await http.patch(`${BASEURI}/comentario/${proyectoId}/${patientId}`, {
        comentarios: data,
      });
      if (request) {
        return request;
      }
    } catch (error) {
      this.$errorNotify(error);
    }
  },

  // eslint-disable-next-line consistent-return
  async printPatientComunidad(data, proyectoId) {
    try {
      const request = await http.post(`${BASEURI}/print/${proyectoId}`, {
        pacientes: data,
      });
      if (request.data) {
        return request.data;
      }
    } catch (error) {
      this.$errorNotify(error);
    }
  },
};

export default PatientsAPI;
